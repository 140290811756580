<template>
    <html>
    <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta name="author" content="Theme Region">
        <meta name="description" content="">
        <title> Revenue Mangement System</title>
        <!-- Bootstrap CSS -->

        <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet">
        <link href="css/animate/animate.min.css" rel="stylesheet">
        <link rel="stylesheet" href="css/bootstrap.css">
        <!--	bootstrap css-->
        <!--  CSS -->
        <link rel="stylesheet" href="css/navigation-temp.css">
        <link rel="stylesheet" href="css/style.css">
        <!-- <link rel="stylesheet" href="css/style2.css"> -->

        <link rel="stylesheet" href="css/form.css">
    </head>

    <body>
    <!--	!--	MENU CONTROLS-->

    <!-- header -->
    <!--		navigation bar -->
    <ToolBarMain/>


    <!--	search receipt pop up-->
    <section class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated d-none" id="search-receipt">
        <div class="container p-5">
            <div class="row p-5">
                <div class="receipt-search-container m-5 d-flex flex-column-md animated" id="get-receipt-container">
                    <div class="col-lg-6 receipt-img-container">
                        <div class="col-lg-8 col-md-12 position-relative p-5">
                            <h2 class="mb-4 text-capitalize text-white">print a receipt for a transaction</h2>
                            <p class="font-14 mb-3 text-capitalize">Make sure all inputs are correct. The process has been made this way to help protect personal data</p>
                        </div>
                    </div>
                    <div class="col-lg-6 p-5 position-relative">
                        <span class="close-receipt-form position-absolute"> <i data-feather="x"></i></span>
                        <form class="transaction-info">
                            <div class="">
                                <h4 class="mb-5 pb-2 text-capitalize">correctly fill in the form bellow to continue</h4>
                            </div>
                            <div class="row m-0">

                                <div  class="form-group col-sm-12 pl-md-0">
                                    <label for="sel1"><small>What was the transacton type?</small></label>
                                    <select class="form-control mb-3" id="sel1" required>
                                        <option>Parking</option>
                                        <option>Business</option>
                                        <option class="text-capitalize">County bills</option>
                                        <option class="text-capitalize">land rates</option>
                                        <option class="text-capitalize">rents</option>
<!--                                        <option class="text-capitalize">e-Construction</option>-->
                                        <option></option>
                                    </select>
                                </div>

                            </div>
                            <div class="row radio-container m-0">
                                <div class="col-12 pl-md-0 mb-3">
                                    <div class="form-check-inline">
                                        <label class="form-check-label">M-Pesa
                                            <input type="radio" class="form-check-input" name="method" required>
                                            <span class="checkmark"></span> </label>
                                    </div>
                                    <div class="form-check-inline">
                                        <label class="form-check-label">NBK
                                            <input type="radio" class="form-check-input" name="method" required>
                                            <span class="checkmark"></span> </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="form-group col-sm-12 pl-md-0 mb-3">
                                    <label for="exampleInputEmail1" class="text-capitalize"><small>what was the refference number for the transaction?</small></label>
                                    <input type="text" class="form-control" id="fname" placeholder="eg M-Pesa ref number" required>
                                </div>
                                <div class="form-group col-sm-12 pl-md-0 mb-3">
                                    <label for="identifier" class="text-capitalize"><small>Unique identifire for transaction eg Number plate, bill number, Business ID etc</small></label>
                                    <input type="text" class="form-control" id="identifier" placeholder="identifier" required>
                                </div>
                                <button type="submit" class="btn btn-primary text-capitalize text-white font-14 submit-btn">get receipt</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--	search receipt pop up-->
    <!--		navigation bar -->
    <!-- header -->
    <!--	MENU CONTROLS-->

    <section id="home-one-info" class="homebg ">
        <!-- view-ad -->
        <div id="home-section" class="parallax-section">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-5 margin-120">
                        <h2 class="text-capitalize mt-5 mb-3">Do it faster</h2>
                        <h1 style="color: #fff" class="mt-3">Welcome To County Self service Portal</h1>
                        <p class="text-capitalize">start paying for your county fees efficiently and surprisingly fast.</p>
                        <div class="ad-btn"> <a href="#" class="btn btn-secondary px-5 py-3 text-black text-uppercase">sign up</a> </div>
                    </div>
                    <div class="col-sm-6 text-center  phone-container d-none d-md-block  d-lg-block"> <img src="img/landing page images/hand-phone.png" class="img animated hand-phone">
                        <div class="shadow d-none"></div>
                    </div>
                </div>
                <!-- row -->
            </div>
            <!-- contaioner -->
        </div>
        <!-- view-ad -->

        <!--	mobile view services-->
        <row class="hidden-links-container">
            <div class="col-lg-5 sub-link-container sub-link-container2">

                <!--            unified busines permit sub-links-->
                <div class="sub-link unified-biz-permit1 left" id="unified-biz-permit">
                    <div class="subicon">
                        <div> <img src="img/new-icons/line/case.svg"> </div>
                        <p>single business permit</p>
                        <small>Select from fee type options below</small> </div>
                    <ul>
<!--                        <li class="d-flex"><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="renew-biz.html">renew UBP</a></li>-->
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="create-biz.html">register New business</a></li>
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a @click="gotTo('print-permit')" href="javascript: void(0);">print permit</a></li>
<!--                        <li><i class="fas fa-arrow-right d-none" data-feather="arrow-right"></i><a href="#">print receipt</a></li>-->
                    </ul>
                </div>
                <!--            unified busines permit sub-links-->
                <!--            land rates-->
                <div class="sub-link land-rate1 left" id="land-rate">
                    <div class="subicon">
                        <div> <img src="img/new-icons/line/land.svg"> </div>
                        <p>land rates</p>
                        <small>Select from fee type options below</small> </div>
                    <ul>
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="land-rates.html">pay land rate</a></li>
<!--                        <li><i class="fas fa-arrow-right d-none" data-feather="arrow-right"></i><a href="#" class="get-a-receipt">print receipt</a></li>-->
                    </ul>
                </div>
                <!--            land rates-->
                <!--            rent sub-links-->
                <div class="sub-link rent1 left" id="rent">
                    <div class="subicon">
                        <div> <img src="img/new-icons/line/key.svg"> </div>
                        <p>rent</p>
                        <small>Select from fee type options below</small> </div>
                    <ul>
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="house-rents.html">house</a></li>
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="market-rents.html">stalls</a></li>
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="#" class="get-a-receipt">receipt</a></li>
                    </ul>
                </div>
                <!--            unified busines permit sub-links-->
                <!--            parking sub-links-->
                <div class="sub-link parking1 left" id="parking">
                    <div class="subicon">
                        <div> <img src="img/new-icons/line/parking.svg"> </div>
                        <p>parking</p>
                        <small>Select from fee type options below</small> </div>
                    <ul>
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a @click="gotTo('daily-parking')" href="javascript: void(0);">daily parking</a></li>
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="seasonal-parking.html">Seasonal parking</a></li>
                        <!--        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="">Sacco</a></li>-->
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="offstreet-parking.html">Offstreet Parking</a></li>
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="parking-penalty.html">Penalties</a></li>
<!--                        <li><i class="fas fa-arrow-right d-none" data-feather="arrow-right"></i><a href="#" class="get-a-receipt">print receipt</a></li>-->
                    </ul>
                </div>
                <!--            unified busines permit sub-links-->
                <!--            wallet sub-links-->
                <div class="sub-link wallet1 left" id="wallet">
                    <div class="subicon">
                        <div> <img src="img/new-icons/line/bill.svg"> </div>
                        <p>county bills</p>
                        <small>Select from fee type options below</small> </div>
                    <ul>
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="create-bill.html">create food handlers bill</a> </li>
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="pay-bill.html">pay for a bill</a> </li>
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="#">Print Miscellaneous </a></li>
                    </ul>
                </div>
                <!--            wallet sub-links-->
                <!--            wallet sub-links-->
<!--                <div class="sub-link construction1 left" id="construction">
                    <div class="subicon">
                        <div> <img src="img/new-icons/line/econstruction.svg"> </div>
                        <p class="text-lowercase">e-Construction</p>
                        <small>Select from fee type options below</small> </div>
                    <ul>
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="construction permit.html">pay Permit</a></li>
                        <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="e-construction invoice.html">print receipt</a></li>
                    </ul>
                </div>-->
                <!--            wallet sub-links-->
            </div>
        </row>

        <!--	mobile view services-->
        <div class="container">
            <div class="row">
                <!-- row -->

                <!--				services go here-->

                <div class="container">
                    <div class="section services position-relative p-md-5 p-sm-4">
                        <!-- single-service -->
                        <div class="col-sm-12 text-capitalize position-relative p-0">
                            <h2>services</h2>
                            <p>Browse through the county services that are available to be paid for online</p>
                            <hr>
                        </div>
                        <section class="options-container p-0" id="options-container">
                            <div class="row">
                                <div class="col-lg-8 p-0">
                                    <!--            option unified business permit-->
                                    <div class="col-md-4 col-lg-4 col-sm-6 option business-permit">
                                        <div class="active-triangle display-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40.5px" height="33.5px">
                                                <path fill-rule="evenodd" stroke="#005c96" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="#005c96" d="M38.084,1.007 L1.015,1.007 L1.015,31.423 L38.084,1.007 Z" />
                                            </svg>
                                        </div>
                                        <div class="icon-container"><img src="img/new-icons/case.svg"></div>
                                        <div class="circular-bg"></div>
                                        <p>Unified Business Permit</p>
                                        <!--                more information about the option-->
                                        <div class="options-info left">
                                            <p>Reneiw or register for your business permit, print your receipts and so on...</p>
                                            <span>
              <div>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
                  <rect fill="none" width="25" height="25" />
                  <polyline fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" points="19.946,35.891 9.057,25 19.947,14.11 " />
                  <line fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="41" y1="25" x2="9.662" y2="25" />
                </svg>
              </div>
              More </span> </div>
                                        <!--                more information about the option-->
                                    </div>
                                    <!--            end of option-->
                                    <!--            option landrates-->
                                    <div class="col-md-4 col-lg-4 col-sm-6 option land-rates">
                                        <div class="active-triangle display-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40.5px" height="33.5px">
                                                <path fill-rule="evenodd" stroke="#005c96" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="#005c96" d="M38.084,1.007 L1.015,1.007 L1.015,31.423 L38.084,1.007 Z" />
                                            </svg>
                                        </div>
                                        <div class="icon-container"><img src="img/new-icons/house.svg"></div>
                                        <div class="circular-bg"></div>
                                        <p>Land rates</p>
                                        <!--                more information about the option-->
                                        <div class="options-info left">
                                            <p>Pay for your land rates or event print your receipts</p>
                                            <span>
              <div>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
                  <rect fill="none" width="25" height="25" />
                  <polyline fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" points="19.946,35.891 9.057,25 19.947,14.11 " />
                  <line fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="41" y1="25" x2="9.662" y2="25" />
                </svg>
              </div>
              More </span> </div>
                                        <!--                more information about the option-->
                                    </div>
                                    <!--            end of option-->
                                    <!--            option rent payment-->
                                    <div class="col-md-4 col-lg-4 col-sm-6  option rents">
                                        <div class="active-triangle display-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40.5px" height="33.5px">
                                                <path fill-rule="evenodd" stroke="#005c96" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="#005c96" d="M38.084,1.007 L1.015,1.007 L1.015,31.423 L38.084,1.007 Z" />
                                            </svg>
                                        </div>
                                        <div class="icon-container"><img src="img/new-icons/key.svg"></div>
                                        <div class="circular-bg"></div>
                                        <p>Rents</p>
                                        <!--                more information about the option-->
                                        <div class="options-info left">
                                            <p>Pay for your house rent, pay your stall rent</p>
                                            <span>
              <div>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
                  <rect fill="none" width="25" height="25" />
                  <polyline fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" points="19.946,35.891 9.057,25 19.947,14.11 " />
                  <line fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="41" y1="25" x2="9.662" y2="25" />
                </svg>
              </div>
              More </span> </div>
                                        <!--                more information about the option-->
                                    </div>
                                    <!--            end of option-->
                                    <!--            option parking fees-->
                                    <div class="col-md-4 col-lg-4 col-sm-6 option parking">
                                        <div class="active-triangle display-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40.5px" height="33.5px">
                                                <path fill-rule="evenodd" stroke="#005c96" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="#005c96" d="M38.084,1.007 L1.015,1.007 L1.015,31.423 L38.084,1.007 Z" />
                                            </svg>
                                        </div>
                                        <div class="icon-container"><img src="img/new-icons/parking.svg"></div>
                                        <div class="circular-bg"></div>
                                        <p>Parking Fees</p>
                                        <!--                more information about the option-->
                                        <div class="options-info left">
                                            <p>Pay for your parking, check your parking status, update your daily parking or even start paying for your seasonal parking fee, this is the link to go to...</p>
                                            <span>
              <div>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
                  <rect fill="none" width="25" height="25" />
                  <polyline fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" points="19.946,35.891 9.057,25 19.947,14.11 " />
                  <line fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="41" y1="25" x2="9.662" y2="25" />
                </svg>
              </div>
              More </span> </div>
                                        <!--                more information about the option-->
                                    </div>
                                    <!--            end of option-->
                                    <!--            option-->
                                    <div class="col-md-4 col-lg-4 col-sm-6  option E-Wallet">
                                        <div class="active-triangle display-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40.5px" height="33.5px">
                                                <path fill-rule="evenodd" stroke="#005c96" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="#005c96" d="M38.084,1.007 L1.015,1.007 L1.015,31.423 L38.084,1.007 Z" />
                                            </svg>
                                        </div>
                                        <div class="icon-container"><img src="img/new-icons/receipt.svg"></div>
                                        <div class="circular-bg"></div>
                                        <p>Bills</p>
                                        <!--                more information about the option-->
                                        <div class="options-info left">
                                            <p>Not sure of where your county fees are to be allocated, just visit this link and you will be assisted</p>
                                            <span>
              <div>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
                  <rect fill="none" width="25" height="25" />
                  <polyline fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" points="19.946,35.891 9.057,25 19.947,14.11 " />
                  <line fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="41" y1="25" x2="9.662" y2="25" />
                </svg>
              </div>
              More </span> </div>
                                        <!--                more information about the option-->
                                    </div>
                                    <!--            end of option-->
                                    <!--            option-->
<!--                                    <div class="col-md-4 col-lg-4 col-sm-6  option construction">
                                        <div class="active-triangle display-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40.5px" height="33.5px">
                                                <path fill-rule="evenodd" stroke="#005c96" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="#005c96" d="M38.084,1.007 L1.015,1.007 L1.015,31.423 L38.084,1.007 Z" />
                                            </svg>
                                        </div>
                                        <div class="icon-container"><img src="img/new-icons/brickwall.svg"></div>
                                        <div class="circular-bg"></div>
                                        <p>E-Construction</p>
                                        &lt;!&ndash;                more information about the option&ndash;&gt;
                                        <div class="options-info left">
                                            <p>Click this option if you want to pay for your contruction fee</p>
                                            <span>
              <div>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
                  <rect fill="none" width="25" height="25" />
                  <polyline fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" points="19.946,35.891 9.057,25 19.947,14.11 " />
                  <line fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="41" y1="25" x2="9.662" y2="25" />
                </svg>
              </div> More </span> </div>
                                        &lt;!&ndash;                more information about the option&ndash;&gt;
                                    </div>-->
                                    <!--            end of option-->
                                </div>
                                <div class="col-lg-4 sub-link-container desktop-link pd-0 text-capitalize">
                                    <!--            unified busines permit sub-links-->
                                    <div class="sub-link unified-biz-permit1 left" id="unified-biz-permit">
                                        <div class="subicon">
                                            <div> <img src="img/new-icons/line/case.svg"> </div>
                                            <p>single business permit</p>
                                            <small>Select from fee type options below</small> </div>
                                        <ul>
<!--                                            <li class="d-flex"><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="renew-biz.html">renew UBP</a></li>-->
                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a @click="gotTo('create-business')" href="javascript: void(0);">register New business</a></li>
                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a @click="gotTo('print-permit')" href="javascript: void(0);">print permit</a></li>
<!--                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="#" class="get-a-receipt">print receipt</a></li>-->
                                        </ul>
                                    </div>
                                    <!--            unified busines permit sub-links-->
                                    <!--            land rates-->
                                    <div class="sub-link land-rate1 left" id="land-rate">
                                        <div class="subicon">
                                            <div> <img src="img/new-icons/line/land.svg"> </div>
                                            <p>land rates</p>
                                            <small>Select from fee type options below</small> </div>
                                        <ul>
                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a @click="gotTo('land-rate')" href="javascript: void(0)">pay land rate</a></li>
<!--                                            <li><i class="fas fa-arrow-right d-none" data-feather="arrow-right"></i><a href="#" class="get-a-receipt">print receipt</a></li>-->
                                        </ul>
                                    </div>
                                    <!--            land rates-->
                                    <!--            rent sub-links-->
                                    <div class="sub-link rent1 left" id="rent">
                                        <div class="subicon">
                                            <div> <img src="img/new-icons/line/key.svg"> </div>
                                            <p>rent</p>
                                            <small>Select from fee type options below</small> </div>
                                        <ul>
                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a @click="gotTo('house-rents')" href="javascript: void(0)">house</a></li>
                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a @click="gotTo('house-rents')" href="javascript: void(0)">stalls</a></li>
<!--                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="#" class="get-a-receipt">receipt</a></li>-->
                                        </ul>
                                    </div>
                                    <!--            unified busines permit sub-links-->
                                    <!--            parking sub-links-->
                                    <div class="sub-link parking1 left" id="parking">
                                        <div class="subicon">
                                            <div> <img src="img/new-icons/line/parking.svg"> </div>
                                            <p>parking</p>
                                            <small>Select from fee type options below</small> </div>
                                        <ul>
                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a @click="gotTo('daily-parking')" href="javascript: void(0);">daily parking</a></li>
                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a @click="gotTo('daily-parking')"  href="javascript: void(0);">Seasonal parking</a></li>
                                            <!--              <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="">Sacco</a></li>-->
<!--                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="offstreet-parking.html">Offstreet Parking</a></li>
                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="parking penalty.html">Penalties</a></li>-->
<!--                                            <li><i class="fas fa-arrow-right d-none" data-feather="arrow-right"></i><a href="#" class="get-a-receipt">print receipt</a></li>-->
                                        </ul>
                                    </div>
                                    <!--            unified busines permit sub-links-->
                                    <!--            wallet sub-links-->
                                    <div class="sub-link wallet1 left" id="wallet">
                                        <div class="subicon">
                                            <div> <img src="img/new-icons/line/bill.svg"> </div>
                                            <p>county bills</p>
                                            <small>Select from fee type options below</small> </div>
                                        <ul>
<!--                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="create-bill.html">create food handlers bill</a> </li>-->
                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a @click="gotTo('pay-bill')" href="javascript: void(0)">pay a bill</a> </li>
<!--                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="#">Print a bill</a></li>-->
                                        </ul>
                                    </div>
                                    <!--            wallet sub-links-->
                                    <!--            wallet sub-links-->
<!--                                    <div class="sub-link construction1 left" id="construction">
                                        <div class="subicon">
                                            <div> <img src="img/new-icons/line/econstruction.svg"> </div>
                                            <p class="text-lowercase">e-Construction</p>
                                            <small>Select from fee type options below</small> </div>
                                        <ul>
                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="">pay Permit</a></li>
                                            <li><i class="fas fa-arrow-right" data-feather="arrow-right"></i><a href="e-construction invoice.html">print receipt</a></li>
                                        </ul>
                                    </div>-->
                                    <!--            wallet sub-links-->
                                </div>
                            </div>
                            <!--    the revenue streams-->
                        </section>
                    </div>
                    <!-- services -->

                    <!--	mobile view services-->

                    <!--	mobile view services-->

                </div>

                <!-- container -->


            </div>
        </div>
    </section>



    <!--			footer-->
    <FooterBar/>


    </body>
    </html>

</template>

<script>
    /* eslint-disable */
        import $ from 'jquery';
        import ScrollMagic from 'scrollmagic';
    import ToolBarMain from "@/components/ToolBar.vue";
    import FooterBar from "@/components/Footer.vue";
    export default {
        name: "HomePage",
      components: {FooterBar, ToolBarMain},
        mounted: function () {
            this.mainJS()
        },
        methods:{
            gotTo(route){
                this.$router.push(route);
            },
            mainJS() {
                $(window).on('load', function() {
                    $('#myModal').modal('show');
                    function explode(){
                        $('#pop').removeClass('d-none');
                        $('#pop').addClass('fadeIn');
                        $('.hand-phone').addClass('bounceInUp')

                    }

//	hiding biz creator

                    $('#create-next').on('click', function(){
                        bizinfo_hide();
                    });
                    $('#location-next').on('click', function(){
                        location_hide();
                    });
                    $('#owners-next').on('click', function(){
                        owner_hide();
                    });

//	span clicking


                    $('.start-clickable').on('click', function(){
                        bizinfo_hide2();

                    });

                    // Get the element, add a click listener...
                    document.getElementById("point-indicator").addEventListener("click", function(e) {
                        // e.target is the CHANGING Element!
                        // If it was an input item
                        if(e.target && e.target.matches(".location-clickable")) {
                            // List item found!  Output the ID!
                            location_hide();
                        }
                    });

                    // Get the element, add a click listener...
                    document.getElementById("point-indicator").addEventListener("click", function(e) {
                        // e.target is the CHANGING Element!
                        // If it was an input item
                        if(e.target && e.target.matches(".info-clickable")) {
                            // List item found!  Output the ID!
                            bizinfo_hide();
                        }
                    });


                    // Get the element, add a click listener...
                    document.getElementById("activity-btns").addEventListener("click", function(e) {
                        // e.target is the CHANGING Element!
                        // If it was an input item
                        if(e.target && e.target.matches(".text-prev")) {
                            // List item found!  Output the ID!

                            location_hide();
                        }
                    });

                    // Get the element, add a click listener...
                    document.getElementById("location-btns").addEventListener("click", function(e) {
                        // e.target is the CHANGING Element!
                        // If it was an input item
                        if(e.target && e.target.matches(".text-prev")) {
                            // List item found!  Output the ID!

                            bizinfo_hide2();
                        }
                    });

                    // Get the element, add a click listener...
                    document.getElementById("owner-btns").addEventListener("click", function(e) {
                        // e.target is the CHANGING Element!
                        // If it was an input item
                        if(e.target && e.target.matches(".text-prev")) {
                            // List item found!  Output the ID!

                            bizinfo_hide();
                        }
                    });

                    $('location-info .text-prev').on('click',function(){
                        bizinfo_hide2();
                    });


//	$('#activity-next').on('click', function(){
//		activity_hide();
//	});
                    function bizinfo_hide2(){
//			$('.biz-info').addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
                        $('.biz-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft').siblings().addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
                        $('.point-indicator span').eq(0).addClass('active').addClass('start-clickable').siblings().removeClass('active');
                    }

                    function bizinfo_hide(){
//			$('.biz-info').addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
                        $('.location-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft').siblings().addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
                        $('.point-indicator span').eq(1).addClass('active').addClass('info-clickable').siblings().removeClass('active');
                    }

                    function location_hide(){
                        $('.owners-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft').siblings().addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
                        $('.point-indicator span').eq(2).addClass('active').addClass('location-clickable').siblings().removeClass('active');
                    }
                    function owner_hide(){
                        $('.activity-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft').siblings().addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
                        $('.point-indicator span').eq(3).addClass('active').addClass('owner-clickable').siblings().removeClass('active');
                    }
//	function owner_hide(){
//			$('.activity-info').addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
//			$('.activity-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft');
//		}




                    function popitem(){
                        $('.pop-item').addClass('tada');
                    }
                    setTimeout(explode, 5000);
                    setTimeout(popitem, 5200);

                    $('.close-pop').on('click',function(){
                        closepop();
                    });

                    $('#close-pricepop').on('click',function(){
                        closepaypop();
                    });



                    function closepop(){
                        setTimeout($('#pop').addClass('d-none'),2000);
                        $('#pop').removeClass('fadeIn');
                        $('.pop-item').removeClass('tada');

                        $('#pop').addClass('zoomOut');
                        $('.pop-item').addClass('rollOut');

                    }

                    function closereceiptform(){
                        setTimeout($('#search-receipt').addClass('d-none'),2000);
                        $('#search-receipt').removeClass('fadeIn');
                        $('#get-receipt-container').removeClass('tada');

                        $('#search-receipt').addClass('zoomOut');
                        $('#get-receipt-container').addClass('rollOut');

                    }


                    function closepaypop(){
                        $('#pay-confirmation-pop').addClass('d-none');
                        $('#pay-confirmation-pop').removeClass(' zoomOut');
                    }


                    function showreceiptform(){
                        $('#search-receipt').removeClass('zoomOut');
                        $('#search-receipt').removeClass('d-none');
                        $('#search-receipt').addClass('fadeIn');
                        $('#get-receipt-container').removeClass('rollOut');
                        $('#get-receipt-container').addClass('tada');
                    }

                    $('.close-receipt-form').on('click',function(){
                        closereceiptform();
                    });

                    $('#get-a-receipt').on('click',function(){
                        showreceiptform();
                    });

                    $('.get-a-receipt').on('click',function(){
                        showreceiptform();
                    });


                });

                (function($) {

                    function closesublinks() {
                        $(".construction1").addClass("left");
                        $(".wallet1").addClass("left");
                        $(".parking1").addClass("left");
                        $(".rent1").addClass("left");
                        $(".unified-biz-permit1").addClass("left");
                        $('.land-rate1').addClass("left");

                    }

                    $(".option").on("click", function() {
                        checkWidth();
                    });
                    $(".hidden-links-container").on("click", function() {
                        $(this).hide();
                    });

                    function checkWidth() {
                        var windowSize = $(window).width();

                        if (windowSize < 993) {
                            $(".hidden-links-container").css("display", "flex");
                        }
                    }

                    // Execute on load

                    $(".option").on("click", function() {
                        closesublinks();
                        $(this).addClass("active").siblings().removeClass("active");
                        $(this).children(".active-triangle").removeClass("display-none");
                        $(this).siblings().children(".active-triangle").addClass("display-none");
                    });

                    $('.business-permit').on("click", function() {
                        $('.unified-biz-permit1').removeClass("left");
                    });

                    $('.construction').on("click", function() {
                        closesublinks();
                        $('.construction1').removeClass("left");
                    });

                    $('.E-Wallet').on("click", function() {
                        $('.wallet1').removeClass("left");
                    });

                    $('.rents').on("click", function() {
                        $('.rent1').removeClass("left");
                    });

                    $('.parking').on("click", function() {
                        $('.parking1').removeClass("left");
                    });

                    $('.land-rates').on("click", function() {
                        $('.land-rate1').removeClass("left");
                    });

                    $(".name-discount").on("click", function() {
                        $(".toogle-user-container").slideToggle();
                    });

                    $(".dp").on("click", function() {
                        $(".toogle-user-container").slideToggle();
                    });

                    $(".toogle-user-container").on("click", function() {
                        $(".toogle-user-container").slideUp();
                    });

                    //	payment methods mpesa direct
                    $(".m-pesa-direct").on("click", function() {
                        $(".m-pesa-direct-inputs").slideToggle().siblings(".payment-details").slideUp();
                        $(this).siblings().children("span").css("opacity", "0");
                        $(".direct-indicator").css("opacity", "1");


                    });

                    //	payment methods mpesa standard
                    $(".mpesa-standard").on("click", function() {
                        $(".m-pesa-standard-inputs").slideToggle().siblings(".payment-details").slideUp();
                        $(this).siblings().children("span").css("opacity", "0");
                        $(".std-indicator").css("opacity", "1");
                    });

                    //	payment methods credits
                    $(".viza-master").on("click", function() {
                        $(".credit-payment-inputs").slideToggle().siblings(".payment-details").slideUp();
                        $(this).siblings().children("span").css("opacity", "0");
                        $(".visa-indicator").css("opacity", "1");
                    });

                    //	payment methods airtel
                    $(".airtell").on("click", function() {
                        $(".airtell-inputs").slideToggle().siblings(".payment-details").slideUp();
                        $(this).siblings().children("span").css("opacity", "0");
                        $(".airtel-indicator").css("opacity", "1");
                    });


                    $('.user-nav .wrap').on('click', function(){
                        $('.user-nav .wrap').removeClass('display-tri');
                        $('.user-nav .wrap').removeClass('active-item')
                        $(this).addClass('display-tri');
                        $(this).addClass('active-item');
                    });

                    $('.acount-details-form input').on('change', function(){
                        $('.submit-btn').prop("disabled", false);
                    })



                })(jQuery);

                $(document).ready(function() {
                    // Toggles the side menu and overlay
                    $('.toogle-btn').on('click', function() {
                        $(".overlay").toggleClass("display-none");
                        // $('.toogle-menu-container').toggleClass('hide show');
                    })

                    // Closes the side menu and removes the overlay when the overlay is clicked
                    $('.overlay').on('click', function() {
                        // $('.toogle-menu-container').toggleClass('hide show');
                        $(".overlay").toggleClass("display-none");
                    })

                    // ScrollMagic for Options
                    var controller = new ScrollMagic.Controller();
                    var ourScene = new ScrollMagic.Scene({
                        triggerElement: '.option',
                        triggerHook: 0.7
                    }).setClassToggle('.option', 'fade-in')
                        .addTo(controller)
                });



//this part affects the transactions page
                (function($) {
                    $(".main-transaction-container").on("click", function(){
                        returnpannels();
                        var parent=$(this).parent();
                        $(this).addClass("active-transaction").parent().siblings().children("active-transaction").removeClass("active-transaction");
                        $(this).siblings(".transaction-more-details").addClass("display-flex");
                        $(this).siblings(".transaction-more-details").slideDown();
                        parente.siblings(".transaction-more-details").removeClass("display-flex");

//		$(".transaction-more-details").eq(index).removeClass("display-none");
//		console.log(index);

                    });

                    $(".close-panel-container").on("click", function(){
                        $(this).parent().slideUp();
                        $(this).parent().removeClass("display-flex");
                    });




                    function returnpannels()
                    {
                        $(".main-transaction-container").removeClass("active-transaction");
                        $(".transaction-more-details").slideUp();
                        $(".transaction-more-details").removeClass("display-flex");

                    }


//	showing and removing assets form

                    $('#add-asset').on('click', function(){
                        showassetsform();
                    });

                    $('#close-asset-form').on('click', function(){
                        hideassetsform();
                    })

                    function showassetsform(){
                        $(".assets-form-container").removeClass('left');
                        $('.assets-container').addClass('right');

                    }

                    function hideassetsform(){
                        $(".assets-form-container").addClass('left');
                        $('.assets-container').removeClass('right');

                    }
                    $('.drop-title').on('click', function(){
                        $(this).parent().siblings('.drop-container').slideToggle();
                    })

                    $('.assets-container .drop-container li').on('click', function(){
                        var thetext=$(this).text();
                        $('.assets-container .the-assets-text').text(thetext);
                        $('.drop-container').slideUp();
                        console.log(thetext);
                    });


//	for the assets form

                    $('.assets-form-container .drop-container li').on('click', function(){
                        var thetext=$(this).text();
                        $('.assets-form-container .the-assets-text').text(thetext);
                        $('.drop-container').slideUp();
                        console.log(thetext);
                    });


//	interchanging forms
                    $('.the-assets-form-group li').on('click', function(){
                        $('.the-assets-form').addClass('d-none');
                    });

                    $('.nyumba').on('click',function(){
                        $('.house-form-inputs').removeClass('d-none');
                    });

                    $('.gari').on('click',function(){
                        $('.car-form-inputs').removeClass('d-none');
                    });

                    $('.soko').on('click',function(){
                        $('.stalls-form-inputs').removeClass('d-none');
                    });

                    $('.gari').on('click',function(){
                        $('.car-form-inputs').removeClass('d-none');
                    });

                    $('.shamba').on('click',function(){
                        $('.land-form-inputs').removeClass('d-none');
                    });


//	payment method
                    $(".pay-container .pay-method").on('click', function(){
                        $(this).addClass('gray-bg').siblings().removeClass('gray-bg');
                    });

//	closing price container


//	function showhideprice2(parking_zone){
//		if(parking_zone!="-- select off-street zone --"){
//		$('.price-container').slideDown();
//	}
                    function showhideprice(parking_zone, car_type){
                        if((parking_zone!="-- select parking zone --") && (car_type!="-- select vehicle type --")){
                            $('.price-container').slideDown();
                        }
                        else if((parking_zone=="-- select parking zone --") || (car_type=="-- select vehicle type --")){
                            $('.price-container').slideUp();
                        }
                    }

                    $('.close-price-container').on('click', function(){
                        $(this).parent().slideUp();
                    });

                    $('#zone').on('change',function(){
                        var parking_zone=$('#zone option:selected').val();
                        var car_type=$('#car_type option:selected').val();
                        showhideprice(parking_zone, car_type);

                    })

                    $('#car_type').on('change',function(){
                        var parking_zone=$('#zone option:selected').val();
                        var car_type=$('#car_type option:selected').val();
                        showhideprice(parking_zone, car_type);
                    })

                    $('#parking-zone').on('change',function(){

                        var parking_zone=$('#parking-zone option:selected').val();
                        var car_type=$('parking zone category').val();
                        showhideprice(parking_zone, car_type);
                    })


//	back to form
                    $('.back-toform').on('click',function(){
                        $('.details-confirm').addClass('fadeOutRight');
                        $('.details-confirm').addClass('d-none');
                        $('.the-transaction-form').removeClass('d-none');
                        $('.the-transaction-form').addClass('fadeInLeft');
                        $('.the-transaction-form').removeClass('fadeOutLeft');
                    });

                    $('.transaction-form-btn').on('click',function(){
                        $('.details-confirm').removeClass('fadeOutRight');
                        $('.details-confirm').removeClass('d-none');
                        $('.the-transaction-form').removeClass('fadeInLeft');
                        $('.the-transaction-form').addClass('fadeOutLeft');
                        $('.the-transaction-form').addClass('d-none');
                        $('.details-confirm').addClass('fadeInRight');

                    });

                    $('.confirm-btn').on('click', function(){
                        $('.btn-txt').addClass('d-none');
                        $('.lds-ellipsis').removeClass('d-none');




                        setTimeout(popprice, 2200);
                        setTimeout(showloader, 4200);

                    });

                    function popprice(){
                        $('#pay-confirmation-pop').removeClass('d-none');
                    }

                    function showloader(){
                        $('.lds-roller').addClass('fadeOut');
                        $('.lds-roller').addClass('position-absolute');
                        $('.confirmed-pay').addClass('fadeIn');
                        $('.confirmed-pay').removeClass('d-none');
                        $('.transacton-instructions').addClass('d-none');
                        $('.transactions-actions').addClass('fadeIn');
                        $('.transactions-actions').removeClass('d-none');

                        $('.payment-status').text('Received!');
                    }

//	delete pop functions
                    $('.cars-container').on('click','.remove-car', function () {
                        $('.notification-container').addClass('fadeIn');

                        $('.notification-container').removeClass('d-none');

                        $('.notification-container').removeClass('fadeOut');
                        $('.notification-container').removeClass('d-none');
                        $('.notification-container .card').addClass('bounceUp');
                        var title="lamba;";
                        var title=$(this).siblings().text();
                        $('#record-name').text(title);

                        console.log(title);
                    });

                    $('.transactions-details-container').on('click','.remove-car', function () {
                        $('.notification-container').addClass('fadeIn');
                        $('.notification-container').removeClass('d-none');
                        $('.notification-container').removeClass('fadeOut');
                        $('.notification-container').removeClass('d-none');
                        $('.notification-container .card').addClass('bounceUp');
                        var title="lamba;";
                        var title=$(this).parent().siblings().children("strong").text();
                        $('#record-name').text(title);

                        console.log(title);
                    });

                    $('.close-delete').on('click', function(){
                        closedeletealert();
                    });


                    function closedeletealert() {
                        $('.notification-container').addClass('fadeOut');
                        $('.notification-container').addClass('d-none');
                        $('.notification-container .card').addClass('fadeOutDown');

                        setTimeout(function () {
                            $('.notification-container').removeClass('fadeOut');
                            $('.notification-container .card').removeClass('fadeOutDown');
                        }, 1000);
                    }

//	sign up form animations
                    $('.toogle-loginfforms li').on('click', function(){
                        $(this).addClass('active-log').siblings().removeClass('active-log');
                    })

                    function showsignup(){
                        $('#signup-form').removeClass('d-none');
                        $('#signup-form').removeClass('bounceOutLeft');
                        $('#signup-form').addClass('bounceInRight');
                        $('#login-form').addClass('d-none');
                        $('#login-form').addClass('bounceOutLeft');

                    }

                    function showlogin(){
                        $('#login-form').removeClass('d-none');
                        $('#login-form').removeClass('bounceOutLeft');
                        $('#login-form').addClass('bounceInRight');
                        $('#signup-form').addClass('d-none');
                        $('#signup-form').addClass('bounceOutLeft');

                    }

                    $('.show-signup').on('click', function(){
                        showsignup();
                    });

                    $('.show-login').on('click', function(){
                        showlogin();
                    });


                    $('.profile-nav .nav-item a, .transactions-link').on('click', function(){
                        $('.my-profile-linker').addClass('d-none');
                        $('.my-profile-linker').removeClass('fadeIn');
                        $(this).addClass('active').parent().siblings().children().removeClass('active');
                        $('.profile-loader').removeClass('d-none');
                        $('.profile-loader').addClass('fadeIn');
                        $('.txt-loader').removeClass('d-none');
                        $('.account-txt-block p,.account-txt-block h4').addClass('d-none');
                        $('.account-txt-block').addClass('mb-5');
                        $('#transactions-minor').removeClass('d-none');
                        $('#transactions-main').removeClass('fadeIn');
                        $('#transactions-main').addClass('d-none');
                        setTimeout(function () {
                            $('.profile-loader').addClass('d-none');
                            $('.profile-loader').removeClass('col-md-12,col-lg-12');
                            $('.profile-loader').removeClass('fadeIn');
                            $('.account-txt-block p,.account-txt-block h4').removeClass('d-none');
                            $('.txt-loader').addClass('d-none');
                            $('.account-txt-block').removeClass('mb-5');
                        }, 1800);





                    });

                    $('.account-link').on('click', function(){
                        $('.account-txt-block h4').text('My account')
                        $('.account-txt-block p').text('Below Are Etails On Your Profile. Do Remember To Save Your Work Once You Have Edited An Item In Your Profile')

                        setTimeout(function () {
                            $('.my-profile-linker').addClass('d-none');
                            $('#my-account').addClass('fadeIn');
                            $('#my-account').removeClass('d-none');


                        }, 1801);
                    });

                    $('.assets-link').on('click', function(){
                        $('.account-txt-block h4').text('My Assets')
                        $('.account-txt-block p').text('This Are Some Of The Assets Registered Under Your Account')

                        setTimeout(function () {
                            $('.my-profile-linker').addClass('d-none');
                            $('#the-assets').addClass('fadeIn');
                            $('#the-assets').removeClass('d-none');

                        }, 1801);
                    });


                    $('.bills-link').on('click', function(){
                        $('.account-txt-block h4').text('My bills')
                        $('.account-txt-block p').text('bellow are a number of bills that were raised for you at the county')

                        setTimeout(function () {
                            $('.my-profile-linker').addClass('d-none');
                            $('#my-bills').addClass('fadeIn');
                            $('#my-bills').removeClass('d-none');

                        }, 1801);
                    });


                    $('.year-list li').on('click',function(){
                        $(this).addClass('checked').siblings().removeClass('checked');
                    })


                    $('.transactions-link').on('click', function(){
                        $('.account-txt-block h4').text('My transactions');
                        $('.account-txt-block p').text('bellow are a number of transactions that you have done within the county');
                        $('.profile-loader').addClass('col-md-12,col-lg-12');
                        $('#transactions-minor').addClass('d-none');



                        setTimeout(function () {

                            $('#transactions-main').addClass('fadeIn');
                            $('#transactions-main').removeClass('d-none');

                            $('.my-profile-linker').addClass('d-none');
                            $('.my-profile-linker').removeClass('fadeIn');

                        }, 1801);


//		create business form changeing


                    });

//	data tables
                    $(document).ready(function() {
                        $('#example').DataTable( {

                            "ordering": false,
                            pageLength: 5,

                        } );


                    } );
                    $(document).ready(function() {
                        $('#bill-table').DataTable( {

                            "ordering": false,
                            pageLength: 5,

                        } );


                    } );
//	data tables

                    $('#carouselExampleSlidesOnly').carousel();

                })(jQuery);

//this part affects the transactions page

            }
        }

    }
</script>

<style scoped>

</style>