<template>

  <div class="header_wrap visible-lg visible-md title-v3 index">
    <header id="header">
      <div class="header-v4 header-wrapper">
        <div class="logo_and_menu-row container">
          <div class="logo-row">
            <div class="maxwidth-theme">
              <div class="row d-flex justify-content-center justify-content-between">
                <div class="logo-block col-md-5 col-sm-5 p-0">
                  <div class="logo">
                    <a href="javascript: void(0);" @click="gotTo('/')"><img :src="county.logo" alt="e-PayNairobi" class="e-logo mr-3" title="HOME"></a>
                    <div class="d-flex flex-column">
                      <h4 class="p-0 mb-1 font-14 fw-semibold">{{capitalizeText(county.countyName)}} County Government</h4>
                      <span class="p-0 m-0">ePayments Platforms</span>
                    </div>
                  </div>
                </div>

                <div class="right-icons pull-right float-right ">
                  <div class="pull-right block-link"></div>
                  <span><i class="fa icon-font"></i></span>
                  <div class="pull-right">
                    <div class="wrap_icon inner-table-block">
                      <div class="profile-pic d-none logged-in">
                        <!-- <img src="../images/Invisible-Man-780294.jpg" height="80px" width="80px"><i class="fa fa-angle-down"></i>-->
                        <ul class="dropdown">
                          <li class="menu_back"> <!--<img src="../images/Invisible-Man-780294.jpg" height="40px" width="40px">-->
                            <ul>
                              <li>email@email.com</li>
                              <li>Username</li>
                            </ul>
                          </li>
                          <li> <a class="dark-color" href="#" title="check out your profile details"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Profile</font></font></span> </a> </li>
                          <!--my profile links-->
                          <li> <a class="dark-color" href="#" title="My ads"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My Ads</font><span class="count-item">0</span></font></span> </a> </li>
                          <!--my profile links-->
                          <li> <a class="dark-color" href="#" title="Your Added shops"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My Shop</font><span class="count-item">0</span></font></span> </a> </li>
                          <!--my profile links-->
                          <li> <a class="dark-color" href="#" title="Your Favourite Ads"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Favourite Ads</font><span class="count-item">0</span></font></span> </a> </li>
                          <!--my profile links-->
                          <li> <a class="dark-color" href="#" title="Archived Ads"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Archived Ads</font></font></span> </a> </li>
                          <!--my profile links-->
                          <li> <a class="dark-color" href="#" title="Pending Approval"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Pending Approval</font><span class="count-item">0</span></font></span> </a> </li>
                          <!--my profile links-->
                          <li> <a class="dark-color" href="#" title="Close Account"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Close Account</font></font></span> </a> </li>
                          <!--my profile links-->
                          <li> <a class="dark-color" href="#" title="Close Account"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Log Out</font></font></span> </a> </li>
                        </ul>

                        <li>email@email.com</li>
                        <li>Username</li>
                      </div>
                    </div>

                    <div class="wrap_icon inner-table-block d-none">
                      <div id="bxdynamic_header-auth-block1_start"></div>
                      <!-- noindex --><a rel="nofollow" title="log in" class="personal-link dark-color animate-load" data-event="jqm" data-param-type="auth" data-param-backurl="/" data-name="auth" href="signin.html"><i class="svg inline big svg-inline-cabinet" aria-hidden="true" title="log in">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">

                        <path data-name="Rounded Rectangle 110" class="loccls-1" d="M1433,132h-15a3,3,0,0,1-3-3v-7a3,3,0,0,1,3-3h1v-2a6,6,0,0,1,6-6h1a6,6,0,0,1,6,6v2h1a3,3,0,0,1,3,3v7A3,3,0,0,1,1433,132Zm-3-15a4,4,0,0,0-4-4h-1a4,4,0,0,0-4,4v2h9v-2Zm4,5a1,1,0,0,0-1-1h-15a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h15a1,1,0,0,0,1-1v-7Zm-8,3.9v1.6a0.5,0.5,0,1,1-1,0v-1.6A1.5,1.5,0,1,1,1426,125.9Z" transform="translate(-1415 -111)"></path>
                      </svg>
                    </i><span class="wrap"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Sign in </font></font></span><span class="title"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My Account</font></font></span></span></a><!-- /noindex -->
                      <div id="bxdynamic_header-auth-block1_end" style="display:none"></div>
                    </div>
                  </div>
                  <div class="pull-right">
                    <div class="d-none wrap_icon inner-table-block btn-span-margin text-capitalize"> <a href="#" ><span id="get-a-receipt">print receipt</span></a> <a href="shop-category.html"><span id="add-shop" class="btn-secondary text-black">create an account</span></a> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-row middle-block bgcolored ">
          <div class="maxwidth-theme" style="background-color: #005c96;">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="menu-only">
                    <nav class="mega-menu sliced ovisible initied">
                      <div class="table-menu">
                        <table>
                          <tbody>
                          <tr class="d-none">
                            <td class="menu-item dropdown catalog wide_menu" style="visibility: visible;"><div class="wrap"> <a class="dropdown-toggle" href="/catalog/">
                              <div><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Services.</font></font>
                                <div class="line-wrapper"><span class="line"></span></div>
                              </div>
                            </a> <span class="tail"></span>
                              <ul class="dropdown-menu text-capitalize">
                                <li class="dropdown-submenu  has_img">
                                  <div class="menu_img"><img src="img/new-icons/case.svg" alt="cars & vehcles" title="cars & vehcles"></div>
                                  <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Single Business Permits</font></font> </span> <span class="arrow"><i></i></span></div>
                                  <ul class="dropdown-menu toggle_menu text-capitalize">
                                    <!--									revenue stream service-->
                                    <li class="menu-item "> <a href="renew-biz.html" title="renew your business permits" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">renew SBP</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->
                                    <!--									revenue stream service-->
                                    <li class="menu-item"> <a href="create-biz.html" title="pay or check for penalties" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">register new business</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->

                                    <!--									revenue stream service-->
                                    <li class="menu-item"> <a href="print-permit.html" title="print a permit" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print permit</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->

                                    <!--									revenue stream service-->
                                    <li class="menu-item"> <a href="#" title="print receipt for specific permit" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->
                                  </ul>
                                </li>
                                <li class="dropdown-submenu  has_img">
                                  <div class="menu_img"><img src="img/new-icons/house.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                  <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">land rates</font></font> </span> <span class="arrow"><i></i></span></div>
                                  <ul class="dropdown-menu toggle_menu text-capitalize">
                                    <!--									revenue stream service-->
                                    <li class="menu-item "> <a href="land-rates.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay land rates</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->
                                    <!--									revenue stream service-->
                                    <li class="menu-item"> <a href="#" class="get-a-receipt" title="print a transaction's receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->
                                  </ul>
                                </li>
                                <li class="dropdown-submenu  has_img">
                                  <div class="menu_img"><img src="img/new-icons/key.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                  <div  title="pay for bills" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">rents</font></font> </span> <span class="arrow"><i></i></span></div>
                                  <ul class="dropdown-menu toggle_menu text-capitalize">
                                    <!--									revenue stream service-->
                                    <li class="menu-item "> <a href="house-rents.html" title="pay for your house rents" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">house rents</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->
                                    <!--									revenue stream service-->
                                    <li class="menu-item"> <a href=market-rents.html title="pay for your market stalls rental fees" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">market stalls</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->
                                    <li class="menu-item"> <a href="#" class="get-a-receipt" title="search for receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">receipt</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->

                                  </ul>
                                </li>
                                <li class="dropdown-submenu  has_img">
                                  <div class="menu_img"><img src="img/new-icons/parking.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                  <div  title="county rental fees" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking fees</font></font> </span> <span class="arrow"><i></i></span></div>
                                  <ul class="dropdown-menu toggle_menu text-capitalize">
                                    <!--									revenue stream service-->
                                    <li class="menu-item "> <a href="daily-parking.html"  title="pay for your daily parking fees" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">daily parking</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->
                                    <!--									revenue stream service-->
                                    <li class="menu-item "> <a href="seasonal-parking.html" title="pay parking for a specified period of time" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">seasonal parking</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->
                                    <li class="menu-item d-none"> <a href="#" title="pay monthly parking for your sacco fleet" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">sacco parking</font> </font> </span> </a> </li>

                                    <!--									revenue stream service-->
                                    <li class="menu-item"> <a href="offstreet-parking.html" title="offstreet parking fees" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">offstreet parking</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->
                                    <!--									revenue stream service-->
                                    <li class="menu-item"> <a href="parking-penalty.html" title="pay for parking penalties" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">penalties</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->
                                    <!--									revenue stream service-->
                                    <li class="menu-item"> <a href="#" class="get-a-receipt" title="phave a look at some of your parking receipts" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">receipts</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->
                                    <!--									revenue stream service-->

                                  </ul>
                                </li>
                                <li class="dropdown-submenu  has_img">
                                  <div class="menu_img"><img src="img/new-icons/receipt.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                  <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">county bills</font></font> </span> <span class="arrow"><i></i></span></div>
                                  <ul class="dropdown-menu toggle_menu text-capitalize">
                                    <!--									revenue stream service-->
                                    <li class="menu-item "> <a href="create-bill.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">create food handlers bill</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->

                                    <!--									revenue stream service-->
                                    <li class="menu-item "> <a href="pay-bill.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay for a bill</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->
                                    <!--									revenue stream service-->
                                    <li class="menu-item"> <a href="#" title="print a transaction's receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print bill</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->

                                  </ul>
                                </li>
                                <li class="dropdown-submenu  has_img">
                                  <div class="menu_img"><img src="img/new-icons/brickwall.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                  <div  title="county construction permits" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;" class="text-lowercase"><font style="vertical-align: inherit;">e-Construction</font></font> </span> <span class="arrow"><i></i></span></div>
                                  <ul class="dropdown-menu toggle_menu text-capitalize">
                                    <!--									revenue stream service-->
                                    <li class="menu-item "> <a href="#" title="pay for a construction permit" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay permit</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->
                                    <!--									revenue stream service-->
                                    <li class="menu-item"> <a href="#" title="print an e-costruction receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                    <!--									revenue stream service-->

                                  </ul>
                                </li>
                              </ul>
                            </div></td>
                            <td class="menu-item dropdown" style="visibility: visible;"><div class="wrap"> <a class="dropdown-toggle" href="#/">
                              <div><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Help </font></font>
                                <div class="line-wrapper"><span class="line"></span></div>
                              </div>
                            </a> <span class="tail"></span>
                              <ul class="dropdown-menu text-capitalize">
                                <li class="  "> <a href="#" title="FAQs"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">FAQs</font></font></span></a> </li>
                                <li class="  "> <a href="/help/delivery/" title="Tearms Of Use"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">guidlines</font></font></span></a> </li>
                              </ul>
                            </div></td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="line-row visible-xs"></div>
      </div>
    </header>
  </div>

  <div class="jqmOverlay search"></div>
  <div id="headerfixed">
    <div class="maxwidth-theme container">
      <div class="logo-row v2 row margin0 menu-row">
        <div class="inner-table-block nopadding logo-block">
          <div class="logo"> <a href="javascript: void(0);" @click="gotTo('/')"><img :src="county.logo" alt="Kisumu ePayment Platform" title="Epayments" height="88px"></a> </div>
        </div>
        <div class="inner-table-block menu-block" style="width: 70%;">
          <div class="navs table-menu js-nav opacity1" >

            <nav class="mega-menu sliced ovisible initied d-none">
              <div class="table-menu">
                <table>
                  <tbody>
                  <tr>
                    <td class="menu-item dropdown catalog wide_menu" style="visibility: visible;"><div class="wrap"> <a class="dropdown-toggle" href="#">
                      <div><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Services</font></font>
                        <div class="line-wrapper"><span class="line"></span></div>
                      </div>
                    </a> <span class="tail"></span>
                      <ul class="dropdown-menu text-capitalize">
                        <li class="dropdown-submenu  has_img">
                          <div class="menu_img"><img src="img/new-icons/case.svg" alt="cars & vehcles" title="cars & vehcles"></div>
                          <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Single Business Permits</font></font> </span> <span class="arrow"><i></i></span></div>
                          <ul class="dropdown-menu toggle_menu text-capitalize">
                            <!--									revenue stream service-->
                            <li class="menu-item "> <a href="renew-biz.html" title="renew your business permits" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">renew SBP</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->
                            <!--									revenue stream service-->
                            <li class="menu-item"> <a href="register-business.html" title="pay or check for penalties" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">register new business</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->

                            <!--									revenue stream service-->
                            <li class="menu-item"> <a @click="gotTo('print-permit')" href="javascript: void(0);" title="print a permit" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print permit</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->

                            <!--									revenue stream service-->
                            <li class="menu-item"> <a href="#" title="print receipt for specific permit" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->
                          </ul>
                        </li>
                        <li class="dropdown-submenu  has_img">
                          <div class="menu_img"><img src="img/new-icons/house.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                          <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">land rates</font></font> </span> <span class="arrow"><i></i></span></div>
                          <ul class="dropdown-menu toggle_menu text-capitalize">
                            <!--									revenue stream service-->
                            <li class="menu-item "> <a href="land-rates.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay land rates</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->
                            <!--									revenue stream service-->
                            <li class="menu-item"> <a href="#" class="get-a-receipt" title="print a transaction's receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->
                          </ul>
                        </li>
                        <li class="dropdown-submenu  has_img">
                          <div class="menu_img"><img src="img/new-icons/key.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                          <div  title="pay for bills" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">rents</font></font> </span> <span class="arrow"><i></i></span></div>
                          <ul class="dropdown-menu toggle_menu text-capitalize">
                            <!--									revenue stream service-->
                            <li class="menu-item "> <a href="house-rents.html" title="pay for your house rents" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">house rents</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->
                            <!--									revenue stream service-->
                            <li class="menu-item"> <a href="market-rents.html" title="pay for your market stalls rental fees" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">market stalls</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->
                            <li class="menu-item"> <a href="#" class="get-a-receipt" title="search for receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">receipt</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->

                          </ul>
                        </li>
                        <li class="dropdown-submenu  has_img">
                          <div class="menu_img"><img src="img/new-icons/parking.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                          <div  title="county rental fees" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking fees</font></font> </span> <span class="arrow"><i></i></span></div>
                          <ul class="dropdown-menu toggle_menu text-capitalize">
                            <!--									revenue stream service-->
                            <li class="menu-item "> <a @click="gotTo('daily-parking')" href="javascript: void(0);" title="pay for your daily parking fees" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">daily parking</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->
                            <!--									revenue stream service-->
                            <li class="menu-item"> <a href="seasonal-parking.html" title="pay parking for a specified period of time" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">seasonal parking</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->
                            <li class="menu-item"> <a href="#" title="pay monthly parking for your sacco fleet" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">sacco parking</font> </font> </span> </a> </li>

                            <!--									revenue stream service-->
                            <li class="menu-item"> <a href="offstreet-parking.html" title="offstreet parking fees" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">offstreet parking</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->
                            <!--									revenue stream service-->
                            <li class="menu-item"> <a href="parking-penalty.html" title="pay for parking penalties" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">penalties</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->
                            <!--									revenue stream service-->
                            <li class="menu-item"> <a href="#" class="get-a-receipt" title="phave a look at some of your parking receipts" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">receipts</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->
                            <!--									revenue stream service-->

                          </ul>
                        </li>
                        <li class="dropdown-submenu  has_img">
                          <div class="menu_img"><img src="img/new-icons/receipt.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                          <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">county bills</font></font> </span> <span class="arrow"><i></i></span></div>
                          <ul class="dropdown-menu toggle_menu text-capitalize">
                            <!--									revenue stream service-->
                            <li class="menu-item "> <a href="pay-bill.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay for a bill</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->

                            <!--									revenue stream service-->
                            <li class="menu-item "> <a href="create-bill.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">create food handlers bill</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->

                            <!--									revenue stream service-->
                            <li class="menu-item"> <a href="#" title="print a transaction's receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print bill</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->

                          </ul>
                        </li>
                        <li class="dropdown-submenu  has_img">
                          <div class="menu_img"><img src="img/new-icons/brickwall.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                          <div  title="county construction permits" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;" class="text-lowercase"><font style="vertical-align: inherit;">e-Construction</font></font> </span> <span class="arrow"><i></i></span></div>
                          <ul class="dropdown-menu toggle_menu text-capitalize">
                            <!--									revenue stream service-->
                            <li class="menu-item "> <a href="#" title="pay for a construction permit" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay permit</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->
                            <!--									revenue stream service-->
                            <li class="menu-item"> <a href="#" title="print an e-costruction receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                            <!--									revenue stream service-->

                          </ul>
                        </li>
                      </ul>
                    </div></td>
                    <td class="menu-item dropdown" style="visibility: visible;"><div class="wrap"> <a class="dropdown-toggle" href="#">
                      <div><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Help</font></font>
                        <div class="line-wrapper"><span class="line"></span></div>
                      </div>
                    </a> <span class="tail"></span>
                      <ul class="dropdown-menu text-capitalize">
                        <li class="  "> <a href="#" title="FAQs"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">FAQs</font></font></span></a> </li>
                        <li class="  "> <a href="/help/delivery/" title="Tearms Of Use"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">guidlines</font></font></span></a> </li>
                      </ul>
                    </div></td>
                    <td class="menu-item dropdown js-dropdown nosave" style="display: none; visibility: visible;"><div class="wrap"> <a class="dropdown-toggle more-items" href="#"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yet</font></font></span> </a> <span class="tail"></span>
                      <ul class="dropdown-menu">
                      </ul>
                    </div></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </nav>


          </div>
        </div>

        <div class="inner-table-block nopadding small-block">
          <div class="wrap_icon wrap_cabinet">
            <div id="bxdynamic_header-auth-block2_start" style="display:none"></div>
            <!-- noindex --><a rel="nofollow" title="log in" class="personal-link dark-color animate-load" data-event="jqm" data-param-type="auth" data-param-backurl="/" data-name="auth" href="#"><i class="svg inline big svg-inline-cabinet" aria-hidden="true" title="log in">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
              <defs>
                <!--  <style>
                      .loccls-1 {
                          fill: #222;
                          fill-rule: evenodd;
                      }
                  </style>-->
              </defs>
              <path data-name="Rounded Rectangle 110" class="loccls-1" d="M1433,132h-15a3,3,0,0,1-3-3v-7a3,3,0,0,1,3-3h1v-2a6,6,0,0,1,6-6h1a6,6,0,0,1,6,6v2h1a3,3,0,0,1,3,3v7A3,3,0,0,1,1433,132Zm-3-15a4,4,0,0,0-4-4h-1a4,4,0,0,0-4,4v2h9v-2Zm4,5a1,1,0,0,0-1-1h-15a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h15a1,1,0,0,0,1-1v-7Zm-8,3.9v1.6a0.5,0.5,0,1,1-1,0v-1.6A1.5,1.5,0,1,1,1426,125.9Z" transform="translate(-1415 -111)"></path>
            </svg>
          </i></a><!-- /noindex -->
            <div id="bxdynamic_header-auth-block2_end" style="display:none"></div>
          </div>
        </div>
        <div class="inner-table-block small-block nopadding inline-search-show" data-type_search="fixed">
          <div class="search-block top-btn"><i class="svg svg-search lg"></i></div>
        </div>
      </div>
    </div>
  </div>

  <div id="mobileheader" class="visible-xs visible-sm">
    <div class="mobileheader-v2">
      <form>
        <!--			mobile search bar-->
        <input type="text" placeholder="search" id="mobile-search-box" class="search-expand">
        <input type="submit">
        <!--			mobile search bar-->
        <!--		mobile search bar quick options -->
        <div class="search-options-panel d-none">
          <!--				search option item	-->
          <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>Samsung Galaxy S8 </b><i>Phones & Accessories</i></p>
        </span>
          <!--				search option item-->
          <!--				search option item	-->
          <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>Nikon D40</b><i>Electronics & Gadgets</i></p>
        </span>
          <!--				search option item-->
          <!--				search option item	-->
          <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>Samsung TV 45" </b><i>Electronics & Gadgets</i></p>
        </span>
          <!--				search option item-->
          <!--				search option item	-->
          <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>BMW X6</b><i>Cars & Vehcles</i></p>
        </span>
          <!--				search option item-->
          <!--				search option item	-->
          <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>Canono T3200</b><i>Electronics & Gadgets</i></p>
        </span>
          <!--				search option item-->
        </div>
        <!--			mobile search bar quick options -->
      </form>
      <i class="txt-box-back text-black"><i data-feather="arrow-left" class="text-black"></i></i> <i class="txt-box-clear"><i data-feather="x" class="text-black"></i></i>
      <div class="burger pull-left"> <i class="svg inline  svg-inline-burger dark svg-inline-cabinet" aria-hidden="true" data-feather="menu"></i> <i class="svg inline  svg-inline-close dark" aria-hidden="true" data-feather="x">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <defs>
            <!-- <style>
                 .cccls-1 {
                     fill: #222;
                     fill-rule: evenodd;
                 }
             </style>-->
          </defs>
          <path data-name="Rounded Rectangle 114 copy 3" class="cccls-1" d="M334.411,138l6.3,6.3a1,1,0,0,1,0,1.414,0.992,0.992,0,0,1-1.408,0l-6.3-6.306-6.3,6.306a1,1,0,0,1-1.409-1.414l6.3-6.3-6.293-6.3a1,1,0,0,1,1.409-1.414l6.3,6.3,6.3-6.3A1,1,0,0,1,340.7,131.7Z" transform="translate(-325 -130)"></path>
        </svg>
      </i> </div>
      <div class="title-block col-sm-6 col-xs-5 pull-left"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{capitalizeText(county.countyName)}} <small>e-Payments</small></font></font></div>
      <div class="right-icons pull-right">
        <div class="pull-right">
          <div class="wrap_icon">
            <button class="top-btn mobile-search-invoke twosmallfont"> <i class="svg inline big text-white" aria-hidden="true" data-feather="search"></i> </button>
          </div>
        </div>
        <div class="pull-right">
          <div class="wrap_icon wrap_cabinet">
            <div id="bxdynamic_header-auth-block3_start" style="display:none"></div>
            <!-- noindex --><a rel="nofollow" title="log-in" class="personal-link dark-color animate-load" data-event="jqm" data-param-type="auth" data-param-backurl="/" data-name="auth" href="#"> <i class="svg inline big white svg-inline-cabinet text-white" aria-hidden="true" title="log-in" data-feather="power"></i> </a><!-- /noindex -->
            <div id="bxdynamic_header-auth-block3_end" style="display:none"></div>
          </div>
        </div>
      </div>
    </div>

    <div id="mobilemenu-overlay"></div>
    <div id="mobilemenu-overlay"></div>
  </div>

  <div id="mobilemenu" class="leftside">
    <div class="mobilemenu-v1 scroller">
      <div class="wrap">
        <div class="prof-container">
          <div class="prof-bg-img"> <img src="img/bg/nairobibg.jpg"/> </div>
          <div class="user-dp"><img src="img/user/default-user.png"></div>
          <p class="mt-2">User Name</p>
          <p class="email-address">Useremail@email.com</p>
          <p class="log-out" title="logout"><i class="icon-off"><i data-feather="power"></i></i></p>
        </div>
        <div class="menu top">
          <ul class="top text-capitalize">
            <li>
              <!--my profile-->
              <a class="dark-color parent" title="How to buy"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">my account</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
              <ul class="dropdown">
                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My Account</font></font></a></li>
                <!--my profile links-->
                <li> <a class="dark-color" href="#" title="check out your profile details"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">my profile</font></font></span> </a> </li>
                <!--my profile links-->
                <li> <a class="dark-color" href="#" title="My ads"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">transactions history</font><span class="count-item d-none">0</span></font></span> </a> </li>
                <!--my profile links-->
                <li> <a class="dark-color" href="#" title="Your registered assets"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My assets</font><span class="count-item">0</span></font></span> </a> </li>
                <!--my profile links-->
                <li> <a class="dark-color" href="#" title="your created bills"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">my bills</font><span class="count-item">0</span></font></span> </a> </li>
                <!--my profile links-->
                <li> <a class="dark-color" href="#" title="change your account's password"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">change password</font></font></span> </a> </li>
                <!--my profile links-->
                <li> <a class="dark-color" href="#" title="log out my account"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">log out</font><span class="count-item d-none">0</span></font></span> </a> </li>
              </ul>
            </li>
            <li class="selected"> <a class="dark-color parent"  title="Catalog"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">single business permit</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
              <ul class="dropdown">
                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">single business permit</font></font></a></li>
                <li> <a class="dark-color" href="#" title="renew permit for an existing business"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">renew permit</font></font></span></a> </li>
                <!--category-->
                <li> <a class="dark-color" href="#" title="register a new business"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">register new business</font></font></span></a> </li>
                <!--category-->
              </ul>
            </li>
            <li> <a class="dark-color parent" title="How to buy"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">land rates</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
              <ul class="dropdown">
                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                <!--				land rate sub menu-->
                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">land rates</font></font></a></li>
                <!--				land rate sub menu-->
                <li> <a class="dark-color" href="#" title="pay for your land rates"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">pay land rate</font></font></span> </a> </li>
                <!--				land rate sub menu-->
                <li> <a class="dark-color" href="#" title="print a land rate receipt"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">print land rate</font></font></span> </a> </li>
                <!--				land rate sub menu-->
              </ul>
            </li>
            <li> <a class="dark-color parent" title="county rental fees"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">rents</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
              <ul class="dropdown">
                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                <!--				rents ssub menu-->
                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">rents</font></font></a></li>

                <!--				rents ssub menu-->
                <li> <a class="dark-color" href="#" title="pay for house rents"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">house rents</font></font></span> </a> </li>
                <!--				rents ssub menu-->
                <li> <a class="dark-color" href="#" title="pay for stall rents"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">stall rents</font></font></span> </a> </li>
                <!--				rents ssub menu-->
                <li> <a class="dark-color" href="#" title="print a receipt"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">receipts</font></font></span> </a> </li>
              </ul>
            </li>
            <li> <a class="dark-color parent" title="Company"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
              <ul class="dropdown">
                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                <!--				rents ssub menu-->
                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking</font></font></a></li>
                <!--				rents ssub menu-->
                <li> <a class="dark-color" href="#" title="pay for your daily parking"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">daily parking</font></font></span> </a> </li>
                <!--				rents ssub menu-->
                <li> <a class="dark-color" href="#" title="parking fee for specified period of time"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">seasonal parking</font></font></span> </a> </li>

                <!--				rents ssub menu-->
                <li> <a class="dark-color" href="#" title="parking for a fleet of cars"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">sacco parking</font></font></span> </a> </li>

                <!--				rents ssub menu-->
                <li> <a class="dark-color" href="#" title="offstreet parking"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">offstreet parking</font></font></span> </a> </li>

                <!--				rents ssub menu-->
                <li> <a class="dark-color" href="#" title="parking penalties"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">penalties</font></font></span> </a> </li>

                <!--				rents ssub menu-->
                <li> <a class="dark-color" href="#" title="transactions receipts"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking receipts</font></font></span> </a> </li>
              </ul>
            </li>
            <li> <a class="dark-color parent" title="county bills"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">county bills</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
              <ul class="dropdown">
                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                <!--				bills ssub menu-->
                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">county bills</font></font></a></li>

                <!--				bills ssub menu-->
                <li> <a class="dark-color" href="#" title="pay for an already created county bill"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">pay for a bill</font></font></span> </a> </li>
                <!--				bills  ssub menu-->
                <li> <a class="dark-color" href="#" title="print a bill"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">print a bill</font></font></span> </a> </li>
                <!--				bills ssub menu-->

              </ul>
            </li>
            <li> <a class="dark-color parent" title="construction permits"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">e-Construction</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
              <ul class="dropdown">
                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                <!--				bills ssub menu-->
                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">e-Construction</font></font></a></li>

                <!--				bills ssub menu-->
                <li> <a class="dark-color" href="#" title="pay for permit"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">pay for permit</font></font></span> </a> </li>
                <!--				bills  ssub menu-->
                <li> <a class="dark-color" href="#" title="print transactions document"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">print permit</font></font></span> </a> </li>
                <!--				bills ssub menu-->

              </ul>
            </li>
          </ul>
          <!--my profile-->

        </div>
      </div>
    </div>
  </div>
  <div id="mobilemenu-overlay"></div>


</template>

<script>

import {authUrl, execute} from "@/api";

export default {
  name: "ToolBarMain",
  data() {
    return{
      county: {
        id: null,
        countyID: null,
        countyName: null,
        tagline:null,
        smsusername: null,
        smspassword:null,
        contact: null,
        center: null,
        address:null,
        email: null,
        headline: null,
        tagline2:null,
        ussd: null,
        logo:null,
        bank: null,
        powered: null,
        stateLogo: null,
        seal: null,
        signature: null,
        QRLink:null,
        ussdCode:null
      }
    }},
  mounted: function () {
    this.getCounty()
  },
  methods:{
    gotTo(route){
      this.$router.push(route);
    },
    getCounty(){
      const data = new FormData();
      data.append("function", "getCounty");
      execute(data,authUrl)
          .then((res) =>{
            if (res.data.success) {
              this.county = res.data.data.county
            }else{
              // alert(res.data.message)
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    capitalizeText(text) {

      if(text == null){
        // Convert the text to lowercase and then capitalize the first letter
        return "";
      }else {
        // Convert the text to lowercase and then capitalize the first letter
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
      }


    }
  }

}
</script>

<style scoped>

</style>